<template>
   <section class="content-container">
      <router-view/>
   </section>
</template>

<!-- <template>
   <div class="news">
      <div class="news-sidebar">
         <SideBar />
      </div>
      <div class="news-container">
         <img src="@/assets/img/forma_1.svg" alt="" class="shape-one" />
         <img src="@/assets/img/forma_3.svg" alt="" class="shape-tw" />
         <div class="news-body">
            <div class="news-body-top">
               <SectionTitle :title="title" subtitle="" />
               <ProfileNav />
            </div>
            <router-view />
            <footerSec></footerSec>
         </div>
      </div>
   </div>
</template> -->

<script>
/* import SideBar from "@/components/SideBar.vue";
import SectionTitle from "@/components/Section/SectionTitle.vue";
import SectionTabs from "../components/Section/SectionTabs.vue";
import ProfileNav from "@/components/Profile/ProfileNav.vue";
import footerSec from "@/components/Section/SectionFooter.vue"; */

export default {
/*    components: {
      SideBar,
      SectionTitle,
      ProfileNav,
      footerSec,
      SectionTabs
   }, */
   data() {
      return {
         title: "Encuestas",
      };
   },
   mounted() {
      
      setInterval(() => {
         this.checkIfUserIsLogged();
      }, 100);
   },
};
</script>